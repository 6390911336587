
.confirm-unsubscribe-container {

    .image {
        max-height: 400px;
    }

    .buttons-container {
        .btn-no {
            margin-right: 16px;
        }
    }

}